import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { AuthenticationService } from './authentication-service.service';
import { ApiClientService } from './api-client.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router,private storage: Storage) { }
  async canLoad() {
    console.log('In auth guard');
    await this.storage.create();
    const token = await this.storage.get('accessToken');
    if(token){
      console.log('token found');
      AuthenticationService.authenticationToken = token;
      ApiClientService.authenticationToken = token;
      return true;
    }
    else{
      console.log('no token found redirecting to login');
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
