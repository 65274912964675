/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';;
import { Observable } from 'rxjs';
import { EventService } from './event.service';

@Injectable()
export class LocationService {
  static locationTrackEvent = 'locationTrackEvent';

  constructor(private eventservice: EventService) {
  }

  private currentLocationWatchId: any;
  private currentLocationCoords: any;
  private intervalId: any;	// Had to change for Ionic4

  public setWatchPositionInterval(interval) {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    const fiveMins = 300;

    this.intervalId = setInterval(() => {

      if (!this.currentLocationWatchId) {
        this.watchDevicePosition();
      }
    }, fiveMins * 1000);
  }

  public clearWatchPositionInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public getCurrentLocation(): Observable<any> {
    return Observable.create(async (obs) => {
      const watchOptions: PositionOptions = {
        timeout: 10000,
        enableHighAccuracy: true,
        maximumAge: 3600
      };
      const resp = (await Geolocation.getCurrentPosition(watchOptions));
      if (resp.coords !== undefined) {
        const lat = resp.coords.latitude;
        const long = resp.coords.longitude;

        this.clearActiveLocationWatch();

        obs.next({ latitude: lat, longitude: long });
        obs.complete();
      }
      else {
        this.clearActiveLocationWatch();
        Observable.throw(resp);
      }
    });
  };

    private watchDevicePosition() {
        this.getCurrentLocation()
            .subscribe((latLng) => {
              this.eventservice.publishData({ eventName: LocationService.locationTrackEvent, latLng });
            });
    }

    private clearActiveLocationWatch() {
        if (this.currentLocationWatchId) {
          this.currentLocationWatchId.unsubscribe();
            this.currentLocationWatchId = null;
        }
    }

    public getPreviousLocationCoords() {
        return this.currentLocationCoords;
    }

    public setCurrentLocationCoords(coords: any) {
        this.currentLocationCoords = coords;
    }

    /*
     *  To get the distance between two positions in FEET
     * */

    public getDistanceBetweenTwoPoints(lat1, lat2, lon1, lon2) {
        const KM_TO_FEET = 3280.84;
      const R = 6371; // Radius of the earth in km
      const dLat = (lat2 - lat1) * Math.PI / 180;  // deg2rad below
      const dLon = (lon2 - lon1) * Math.PI / 180;
      const a =
            0.5 - Math.cos(dLat) / 2 +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            (1 - Math.cos(dLon)) / 2;

      const distance = R * 2 * Math.asin(Math.sqrt(a));
        return Number(distance * KM_TO_FEET);
    }
}
