import {Injectable} from '@angular/core';

@Injectable()
export class NumberService {
    public getFormatted(val: number, length: number) {
        let result = val.toString();

        while (result.length < length) {
            result = '0' + result;
        }

        return result;
    }
}