/* eslint-disable no-var */

import { environment } from "src/environments/environment";

/* eslint-disable @typescript-eslint/no-namespace */
export namespace AppSettings {
    // Google maps requires custom images to be hosted somewhere; this setting allows us to use the
    // prod server for hosting map-related images while still using localhost for testing everything else
	//Alert sources from db
	    // None = 0,
        // Scout = 1,
        // Analyst = 2,
        // Manager = 3
  export var imagesRootUrl = 'https://webapp.alpharecon.com/images/';

	export var watchPositionInterval = 30000;
    export var watchForAlertUpdatesInterval = 30000;
  export var webSiteHelpUrl = 'https://www.alpharecon.com/resources';
  export var supportEmailAddress = 'support@alpharecon.com';
  export var webSiteUrl = 'https://www.alpharecon.com';
  export var webSiteRequestLocationUrl = 'https://www.alpharecon.com';
  export var webSitePurchaseUrl = 'https://www.alpharecon.com/subscription/signup';
  export var webSitePricingUrl = 'https://www.alpharecon.com/pricing';
  export var webSiteAppUrl = 'https://www.goreconapp.com';
  export var gcmSenderId = '1094390772671';
	export var ramenOrganizationId = '56c30e1a646236231c8a1a00';
	export var isAppTravelRecon = true;
	export var testFairyAppToken = '9e24b5aca619666500d82750fe197805424d6634';
	export var registerUrl = 'https://www.alpharecon.com/user/register';
	export var forgotPasswordUrl = 'http://www.alpharecon.com/user/password';
	export var avatarGetUrl = 'http://www.alpharecon.com/mobile/profile/image';
	export var aerisToken = 'lQmdtYzdnh1uRDIT8eceI_wEP7whT4HrQGpMlNkNlHLYX6UQ1vOUta3tNF8b8T';
	export var staleContactPeriod = 1000 * 60 * 15; // 15 minutes in milliseconds = 1000 ms * 60 s * 15 min
    export var isRunLocally = false;
  export var ravenApiKey = 'https://7712371945c54dd091ca9b5a8a3a4468@sentry.io/97671';
  export var iCommUrl = 'https://client-uat.icommconnect.com/Home/Directory?clientSubscriptionGuId=';
    export var alertDescriptionMaxLength = 200;
  export var stripeKeyTest = 'pk_test_YZGTB3eE9US9b3K3GXSb0Wxe';
  export var stripeKeyLive = 'pk_live_qTEnwmOaOn1jfg8fsnEDMeck';
    export var isStripeLiveMode = false;
    export var defaultZoom = 10;
  export var appVersion = '1.6.0';

	// Prod Web API root URL
	//export var webApiRootUrl = "https://webapitest.alpharecon.com/";
	// export var webApiRootUrl = "https://arwebapiqa.azurewebsites.net";

  //export var webApiRootUrl = 'https://securecon-api-dev.alpharecon.com/';
  export var webApiRootUrl = environment.webApiRootUrl;

	//export var webApiRootUrl = "https://webapitest.alpharecon.com/";
	//export var webApiRootUrl = "http://192.168.18.78:80/";
	// export var webApiRootUrl = "https://arwebapidev.azurewebsites.net";

	// Dev web server
	//webApiRootUrl: "http://localhost:56700/"
	//webApiRootUrl: "http://travelrecon.me/"
};
