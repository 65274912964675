

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { Diagnostic } from '@ionic-native/diagnostic/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiClientService } from './services/api-client.service';
import { AppSettingsService } from './services/app-settings-service.service';
import { AuthenticationService } from './services/authentication-service.service';
import { CameraPhotoService } from './services/camera-photo.service';
import { DateService } from './services/date-service.service';
import { DeviceService } from './services/device-service.service';
import { LocalizationService } from './services/localization-service.service';
import { LocationService } from './services/location-service.service';
import { MapPinService } from './services/map-pin.service';
import { MapService } from './services/map.service';
import { MixpanelService } from './services/mixpanel-service.service';
import { NumberService } from './services/number-service.service';
import { SpinnerService } from './services/spinner.service';
import { UserService } from './services/user.service';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, HttpClientModule,
    IonicStorageModule.forRoot(), IonicModule.forRoot(), AppRoutingModule],
  providers: [
    ApiClientService,
    UserService,
    DeviceService,
    AuthenticationService,
    AppSettingsService,
    LocalizationService,
    MapService,
    MapPinService,
    SpinnerService,
    LocationService,
    DateService,
    CameraPhotoService,
    NumberService,
    MixpanelService,

    Diagnostic,

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule { }
