import {Injectable} from '@angular/core';
import {LabelType} from '../infrastructure/localization/label-type-enum'
import { Observable } from 'rxjs';
import { ApiClientService } from './api-client.service';
import { EventService } from './event.service';
import { LocalizationStorage } from './localization-storage.service';


@Injectable()
export class LocalizationService {
    constructor(
        private apiClient: ApiClientService,
        private eventService: EventService
    ) {
    }
     
    public get(labels: LabelType[]) {
        let params: URLSearchParams = new URLSearchParams();

        for (let a = 0; a < labels.length; a++) {
            params.append('types', labels[a].toString());    
        }

        return this.apiClient.get('api/localization/get' + '?__' + (new Date).getTime(), params);
    }

    public initLabels() {
        return Observable.create((obs) => {
            this.apiClient.get("api/localization/getall" + '?__' + (new Date).getTime())
                .subscribe((labels) => {
                    LocalizationStorage.setLabels(labels);

                    let languagechangedevent = "LanguageChangedEvent";
                    this.eventService.publishData(
                        {eventName:languagechangedevent}
                  );
                    console.log(languagechangedevent + ": fired");

                    obs.next();
                    obs.complete();
                },
                (error) => obs.error(error));
        });
    }
}