import { Injectable, EventEmitter } from '@angular/core';
import { EventService } from './event.service';

@Injectable()
export class LocalizationStorage {
    public static labels: any = [];
    public evnetService: EventService;
    public static onLabelsChangedEvent: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    public static getLabels(): void {
        return LocalizationStorage.labels;
    }

    public static setLabels(labels: Array<any>): void {
        this.labels = labels;
        this.onLabelsChangedEvent.emit(this.labels);
    }
}