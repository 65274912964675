import {Injectable} from '@angular/core';
import { AppSettings } from '../app-settings';

@Injectable()
export class MixpanelService {

    constructor() {
    }

    public trackAppLoaded(): void {
        this.track("The app was loaded");
    }

    public trackCloseApp(pageName): void {
        this.track("The app was closed. Page:" + pageName);
    }

    public trackOpenApp(pageName): void {
        this.track("The app was opened. Page:" + pageName);
    }

    public trackGroupEdited(): void {
        this.track("Group was edited");
    }
   
    public trackNewGroupCreated(): void {
        this.track("New group was created");
    }

    public trackCheckIn(): void {
        this.track("User has checked-in");
    }

    public trackFriendRequestWasSent (): void {
        this.track("User has sent a friend request");
    }

    public trackEmailWithInviteWasSend (): void {
        this.track('Email with Invitation to join the app was sent');
    }

    public trackSignIn(user): void {
        this.track("Singed in. User: " + user, { 'user' : user });
    }

    public trackAlertFilterWasApplied(): void {
        this.track("Alert filter was applied");
    }

    public trackWhatIsGoReconLinkClicked(): void {
        this.track("'What is go recon link cliked' on the login page");
    }

    public trackAlertReport(): void {
        this.track("Alert was reported");
    }

    public trackEmergencyReport(): void {
        this.track("Emergency was reported");
    }

    public trackViewAlertOrEmergency(isEmergency): void {
        let page = isEmergency ? "emergency" : "alert";
        this.track("Viewed " + page + " detail page");
    }

    public trackViewAlertOrEmergencyOnMap(): void {
        this.track("View alert or emergency on map");
    }

    public trackMissionsVisit(): void {
        this.track("My Missions page was visited");
    }

    public trackViewReports(): void {
        this.track("Intel Reports List was opened");
    }

    public trackOpenIntelReport(reportName, destinationName): void {
        this.track("Intel report was opened. Type: " + reportName + ". Destination: " + destinationName,
            {'Report Type': reportName, 'Destination': destinationName});
    }

    public trackInviteUserToGroup(): void {
        this.track("User was invited to group");
    }

    public trackUserDeletedAContact(): void {
        this.track("User delete a contact");
    }

    public trackFriendRequestAccepted(): void {
        this.track("User has accepted a friend request");
    }

    public trackFriendRequestRejected(): void {
        this.track("User has rejected a friend request");
    }

    public trackUserClickedOnWatchTutorialBtn(page): void {
        this.track("User clicked 'Watch tutorial' for the page: " + page);
    }

    public trackUserFinishedTutorial(page): void {
        this.track("User watched tutorial for the page: " + page);
    }

    public trackUserSkippedTutorial(page): void {
        this.track("User skipped tutorial for the page: " + page);
    }

    public trackUserClickedOnWatchTrTerminologyBtn(): void {
        this.track("User clicked 'Watch Tr Terminology'");
    }

    public trackLanguageWasChanged(): void {
        this.track("Language was changed");
    }

    public trackVisitAdditionalProducts(): void {
        this.track("User has visited Additional Products page");
    }

    public trackContactSearch(): void {
        this.track("User has searched for new contacts");
    }

    public trackUserChangedPurchasedDestination(destination, on): void {
        let onOff = on ? "on" : "off";
        this.track("User has turned " + onOff + " notifications for destination: " + destination);
    }

    public trackUserChangedCurrentDestination(from, to): void {
        if (!from)
            from = "Current location";

        if (!to)
            to = "Current location";

        this.track("User changed destination from " + from + " to " + to, {'Previous Destinaiton' : from, 'New Destination': to});
    }

    public trackUserChangedLocationReportFrequency (): void {
        this.track("User changed location report frequency");
    }

    public trackUserChangedObfuscateLocation(): void {
        this.track("User changed obfuscate location");
    }

    public trackUserChangedShowAlertsNotifications(on): void {
        let onOff = on ? "on" : "off";
        this.track("User turned push notifications " + onOff);
    }

    public trackUserChangedAlertsNotificationFrequency (): void {
        this.track("User changed alert notifications frequency");
    }

    public trackUserChangedMaxNumberOfAlertsNotifications (): void {
        this.track("User changed max number if alerts notifications");
    }

    public trackUserChangedEmergencyNotificationEmail(): void {
        this.track("User changed emergency notification email");
    }

    private track(key: string, params?): void {
        let w: any = window;

        var d = new Date();

        if (!params)
            params = {};

        params.Date = d.toLocaleString();
        params.App = AppSettings.isAppTravelRecon ? "TravelRecon" : "GoRecon";

        w.mixpanel.track(key, params);
    }
}