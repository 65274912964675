import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppSettings } from 'src/app/app-settings';
import { AlertServiceService } from 'src/app/services/alert-service.service';
import { DateService } from 'src/app/services/date-service.service';
import { MapPinService } from 'src/app/services/map-pin.service';
import { MapService } from 'src/app/services/map.service';
import { MixpanelService } from 'src/app/services/mixpanel-service.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-view-alert',
  templateUrl: './view-alert.page.html',
  styleUrls: ['./view-alert.page.scss'],
})
export class ViewAlertPage implements OnInit {
  w: any = window
  viewModel: any = {
    alert: null,
    address: null
};

@Input() alertId: any;
@Input() isMapObject: any;


  constructor(private spinnerService: SpinnerService,private modalCtrl: ModalController, private dateService: DateService, private mixpanelService: MixpanelService, private mapService: MapService, private mapPinService: MapPinService, private alertsService: AlertServiceService) { }

  ngOnInit() {
    this.viewAlert();
  }

  viewAlert(){
    if(!this.isMapObject){
      this.spinnerService.showLoading("Fetching recon details...");
    }
    this.alertsService.getAlert(this.alertId)
    .subscribe(async alert => {
        if(alert.analystAlertPriorityId == undefined){
            alert.analystAlertPriorityId = 1;
        }
        this.viewModel.alert = alert;
        this.spinnerService.dismiss();
        this.viewModel.address=alert.address;
        this.viewModel.alert.address = this.getAddress(alert);
        this.viewModel.alert.alertSourceName = this.getSourceName(alert);
        this.mixpanelService.trackViewAlertOrEmergency(this.viewModel.alert.isEmergency);
        this.addMap();
        await this.spinnerService.dismiss();
        },async (error) => {
          console.log('dismissing initImageLoad');
          await this.spinnerService.dismiss();
          if(error.status == 401){
            console.log('Unauthorized 401 error');
            setTimeout(() => {
              this.viewAlert();
            },2000);
          }

    });
  }

  dismissModal(){
    this.modalCtrl.dismiss();
  }

  public getDateTimeFormatted(date, format): string {
    return this.dateService.getDateTimeFormatted(new Date(date), format);
}

  getAlertIcon(alert: any): string {
    return `assets/MapIcons/List/icn_alert_${alert.alertTypeCategoryId}_${alert.analystAlertPriorityId}.png`;
}

public getSourceName(alert): string {
    if (alert.source === 1) {
        return 'Scout Alert';
    }

    if (alert.source === 2) {
        return 'Analyst Alert';
    }

    return "-";
}

getAddress(alert) {
    return (alert.country && alert.city ? alert.country + ", " + alert.city : alert.addressFormatted);
}



  async addMap() {
    let map = this.mapService.createMap('alertMap'+this.alertId, 8);
    let coords ={};
    console.log(this.viewModel);
    if(this.viewModel.address.length > 0){
       coords = [this.viewModel.address[0].long, this.viewModel.address[0].lat];
    }
    else if(this.viewModel.alert.addressFormatted ){
      let address = await this.mapService.getCoordsBasedOn(this.viewModel.alert.addressFormatted ).toPromise();
      coords = [address.longitude, address.latitude];
    }
    map.setCenter(coords);
    this.addMarker(coords,map);

    map.on('load', () => {
            this.mapPinService.clearCluster(map, "alert-cluster");
            let feature = this.mapPinService.getFeature(coords,
                this.viewModel.alert.isEmergency ? 'map_pin_red' : 'map_pin_orange',
                { width: 17, height: 28 },
                { x: -8, y: -28 });
            let featureCollection = this.mapPinService.getFeatureCollection([feature]);
            this.mapPinService.createCluster(map, "alert-cluster", featureCollection, "#51bbd6");
        });

    //this.mapService.setMapLayers(map, ViewAlert.pageName); todo mapbox gl
}


addMarker(coords,map){
  let marker = new this.w.mapboxgl.Marker()
  .setLngLat(coords).setPopup(new this.w.mapboxgl.Popup({ offset: 25 }).setText(this.viewModel.alert.address))
  marker.addTo(map);

}

}
