/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { NumberService } from './number-service.service';
import * as moment from 'moment';


@Injectable()
export class DateService {
    private numberService: NumberService;

    private monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June',
      'July', 'August', 'September',
      'October', 'November', 'December'
    ];

    private dayNames = [
      'Monday', 'Tuesday', 'Wednesday',
      'Thursday', 'Friday', 'Saturday',
      'Sunday'
    ];

    private dayShortNames = [
      'Mon', 'Tue', 'Wed',
      'Thu', 'Fri', 'Sat',
      'Sun'
    ];

    private dateTimeFormats: Array<any> = [
        moment.ISO_8601,
      'YYYY.MM.DD HH:mm',
      'DD.MM.YYYY HH:mm',
      'DD.MM.YY HH:mm',
      'MM.DD.YYYY HH:mm',
      'MM.DD.YY HH:mm',
      'YYYY/MM/DD HH:mm',
      'YYYY/MM/DD HH:m',
      'YYYY/MM/DD H:mm',
      'YYYY/MM/DD H:m',
      'YYYY/MM/DD h:mm',
      'YYYY/MM/DD h:m',
      'YYYY/MM/DD hh:m',
      'YYYY/MM/DD hh:mm'
    ];

    private timeFormats: Array<any> = [
        moment.ISO_8601,
      'HH:mm',
      'HH:m',
      'H:mm',
      'H:m',
      'hh:mm',
      'hh:m',
      'h:mm',
      'h:m'
    ];

    private dateFormats: Array<any> = [
        moment.ISO_8601,
      'YYYY.MM.DD',
      'YYYY/MM/DD',
      'YYYY MM DD'
    ];

    constructor(numberService: NumberService) {
        this.numberService = numberService;
    }

    public getDateTimeFormatted(date: Date, dateFormat: string) {
      let value = dateFormat;

      if (value.indexOf('dddd') >= 0) {
        value = value.replace('dddd', this.dayNames[date.getDay() - 1]);
      }

      if (value.indexOf('ddd') >= 0) {
        value = value.replace('ddd', this.dayShortNames[date.getDay() - 1]);
      }

      if (value.indexOf('dd') >= 0) {
        value = value.replace('dd', this.numberService.getFormatted(date.getDate(), 2));
      }

      if (value.indexOf('MMMM') >= 0) {
        value = value.replace('MMMM', this.monthNames[date.getMonth()]);
        }

      if (value.indexOf('MMM') >= 0) {
        value = value.replace('MMM', this.monthNames[date.getMonth()].substr(0, 3));
        }

      if (value.indexOf('MM') >= 0) {
        value = value.replace('MM', this.numberService.getFormatted(date.getMonth() + 1, 2));
        }

      if (value.indexOf('yyyy') >= 0) {
        value = value.replace('yyyy', this.numberService.getFormatted(date.getFullYear(), 4));
        }

      if (value.indexOf('yy') >= 0) {
        value = value.replace('yy', date.getFullYear().toString().substr(2, 2));
        }

      if (value.indexOf('LT') >= 0) {
        value = value.replace('LT', this.formatLTime(date));
        }

      if (value.indexOf('HH') >= 0) {
        value = value.replace('HH', this.numberService.getFormatted(date.getHours(), 2));
        }

      if (value.indexOf('H') >= 0) {
        let hours = date.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
        hours = hours ? hours : 12;

        value = value.replace('H', this.numberService.getFormatted(hours, 1));
            value += ` ${ampm}`;
        }

      if (value.indexOf('mm') >= 0) {
        value = value.replace('mm', this.numberService.getFormatted(date.getMinutes(), 2));
        }

        return value;
    }

    private formatLTime(date: Date) {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      const minutesStr = minutes < 10 ? '0' + minutes : minutes;
      const strTime = hours + ':' + minutesStr + ' ' + ampm;
        return strTime;
    }

    public validateDateTime(datetime: string): boolean {
        return moment(datetime, this.dateTimeFormats, true).isValid();
    }

    public validateDate(date: string): boolean {
        return moment(date, this.dateFormats, true).isValid();
    }

    public validateTime(time: string): boolean {
        return moment(time, this.timeFormats, true).isValid();
    }

    public isFutureDate(dateTime: string): boolean {
        return moment(dateTime) > moment();
    }

    public getDateTime(datetime: string): any {
        return moment(datetime, this.dateTimeFormats, true);
    }

    public getDate(date: string): any {
        return moment(date, this.dateFormats, true);
    }

    public getTime(time: string): any {
        return moment(time, this.timeFormats, true);
    }
}
