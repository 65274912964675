/* eslint-disable max-len */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable eqeqeq */
import { Injectable, ViewChild, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventService } from './event.service';
import { ApplicationEvents } from '../infrastructure/events/application-events';
import { AppSettings } from '../app-settings';
import { AuthenticationService } from './authentication-service.service';
import { SpinnerService } from './spinner.service';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Route, Router } from '@angular/router';



@Injectable()
export class ApiClientService {

  static authenticationToken: string;
  private requestsQueue: any = {};
  private requestsProcessingInterval: any = null;

  constructor(private http: HttpClient, private eventService: EventService,
    private toastCtrl: ToastController, private storage: Storage,private router: Router) {
    this.initRequestsProcessingInterval();
  }

  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'top'
    });

    await toast.present();
  }


  private initRequestsProcessingInterval(): void {
    if (!this.requestsProcessingInterval) {
      this.requestsProcessingInterval = setInterval(() => {
        for (const key in this.requestsQueue) {
          if (this.requestsQueue[key].expiration <= this.getCurrentTicks()) {
            this.hideLoader(this.requestsQueue[key].isBackground);
            this.endRequest(key);
            this.eventService.publishData(
              { eventName: ApplicationEvents.onInsufficientDataAccessEvent }
            );
          }
        }
      }, 1000);
    }
  }

  public getDefaultHeaders(url: string = "") {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    if (url == "api/mobile/account/platform/registerId") {
      //console.log(url);
      //headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
      headers = headers.append('Content-Type', 'application/json');
    }
    else {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (ApiClientService.authenticationToken) {
      headers = headers.append('Authorization', 'Bearer ' + ApiClientService.authenticationToken);
    }
    return headers;
  }

  public getDefaultHeadersForOauth() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    return headers;
  }

  private getAbsoluteUrl(relativePath: string): string {
    const apiUrl = AppSettings.webApiRootUrl;
    return `${apiUrl}${relativePath}`;
  }

  public post(url, params: string) {
    if (url == 'oauth/token' || url == 'api/Settings/ChangeUserAlertsNotificationsShow' ||
      url == 'api/Settings/SetUserAlertsNotificationsInterval' ||
      url == 'api/Settings/SetUserAlertsNotificationsMaxNumber' ||
      url == 'api/Settings/SetEmergencyNotificationPhone' ||
      url == 'api/Settings/SetEmergencyNotificationEmail' ||
      url == 'api/Settings/SetUserMapZoom') {
      return this.intercept(this.http.post(this.getAbsoluteUrl(url), params, { headers: this.getDefaultHeadersForOauth() }));
    }
    else {
      return this.intercept(this.http.post(this.getAbsoluteUrl(url), params, { headers: this.getDefaultHeaders(url) }));
    }
  }

  public delete(url, params) {
    return this.intercept(this.http.delete(this.getAbsoluteUrl(url), { headers: this.getDefaultHeaders() }));
  }

  public put(url, params) {
    return this.intercept(this.http.put(this.getAbsoluteUrl(url), params, { headers: this.getDefaultHeaders() }));
  }

  public postBackground(url, params) {
    return this.intercept(this.http.post(this.getAbsoluteUrl(url), params, { headers: this.getDefaultHeaders() }), true);
  }

  public get(url, params: any = '') {
    return this.intercept(this.http.get(this.getAbsoluteUrl(url), { headers: this.getDefaultHeaders() }));
  }

  public getBackground(url, params: any = '') {
    return this.intercept(this.http.get(this.getAbsoluteUrl(url), { headers: this.getDefaultHeaders() }), true);
  }

  // this method was removed from usage due to issues with Android 4.4.2 version
  // it's intention was to show the error message when server hasn't responded and the device doesn't have internet connection
  // it added callbacks to xhr request processing by redefining 'XMLHttpRequest.prototype.send' method
  // (see addXMLRequestCallback method below for details)
  // on Android 4.4.2 devices xhr callbacks prevented html view from loading for some reason, so none of the page could be loaded on device
  // the functionality of this method was replaced by handling case with no internet connection in 'intercept' method (below)
  public initXhrInterceptor() {
    this.addXMLRequestCallback((xhr) => {
      if (xhr.status === 0 && !navigator.onLine) { //if no internet
        this.eventService.publishData({ eventName: ApplicationEvents.onErrorEvent, error: 'We\'ve lost connection. Please turn on your data or wifi to proceed' });
      }
    });
  }

  private showLoader(isBackground) {
    if (isBackground) {
      return;
    }

    // this.events.publish(ApplicationEvents.appProcessStartEvent);
    this.eventService.publishData({ eventName: ApplicationEvents.appProcessStartEvent });

    // var loadingModal = this.app.getComponent('loading');
    // if (loadingModal)
    //     this.loadingModal.show();
  }

  private hideLoader(isBackground) {
    if (isBackground) {
      return;
    }

    this.eventService.publishData({ eventName: ApplicationEvents.appProcessEndEvent });
    // var loadingModal = this.app.getComponent('loading');
    // if (loadingModal)
    //     loadingModal.hide();
  }

  private secondsToMilliseconds(seconds: number): number {
    return (seconds || 0) * 1000 * 10000;
  }

  private getCurrentTicks(): number {
    const date = new Date();

    // the number of .net ticks at the unix epoch
    const epochTicks = 621355968000000000;

    // there are 10000 .net ticks per millisecond
    const ticksPerMillisecond = 10000;

    const ticks = ((date.getTime() * ticksPerMillisecond) + epochTicks);

    return ticks;
  }

  private getExpirationTicks(expiration?: number): number {
    if (!expiration) {
      return null;
    }

    return this.getCurrentTicks() + this.secondsToMilliseconds(expiration);
  }

  private guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  private startRequest(requestId: string, isBackground: boolean): void {
    this.requestsQueue[requestId] = {
      expiration: this.getExpirationTicks(30),
      status: 'processing',
      isBackground
    };
  }

  private endRequest(requestId: string): void {
    if (this.requestsQueue[requestId]) {
      delete this.requestsQueue[requestId];
    }
  }

  private intercept(observable: Observable<any>, isBackground?: boolean): Observable<any> {
    const requestId = this.guid();
    console.log('Intercepting....');
    this.startRequest(requestId, isBackground);
    return Observable.create(obs => {
      this.showLoader(isBackground);
      observable
        .subscribe(
          (r) => {
            this.endRequest(requestId);
            this.hideLoader(isBackground);

            obs.next(r);
            obs.complete();
          },
          (error) => {

            // if there's internet connection we finalize the request
            if (navigator.onLine) { this.endRequest(requestId); }

            // otherwise we don't finalize the request and just handle error
            this.hideLoader(isBackground);
            this.handleError(error);

            obs.error(error);
          });
    });
  }

  private async handleError(error) {
    if (error.status === 401) {
      console.log('Found 401 error');
      (await this.refreshToken()).subscribe((result:any) => {
        console.log(result);
        this.presentToast('Re-Authorization done successfully.');
        this.refreshUser(result.access_token, true, result.refresh_token);
      }, (error) => {
        console.log(error);
        this.presentToast('Failed to Refresh token. Please login.');
        this.eventService.publishData({ eventName: ApplicationEvents.onUnauthorizedEvent });
      });
    }

    if (error.status === 200) {
      this.eventService.publishData({ eventName: ApplicationEvents.onErrorEvent, error: 'Unknown Error Occured. Server response not received.' });
    }
  }

  public async refreshToken() {
    const refresh_token = encodeURIComponent(await this.getRefreshToken());
    return this.http.post(this.getAbsoluteUrl('oauth/token'), 'grant_type=refresh_token&refresh_token=' + refresh_token);
  }

  public refreshUser(accessToken: string, keepSigned: boolean, refresh_token: string): boolean {
    AuthenticationService.authenticationToken = accessToken;
    ApiClientService.authenticationToken = accessToken;
    this.storage.set('accessToken', accessToken);
    this.storage.set('keepSigned', keepSigned);
    this.storage.set('refreshToken', refresh_token);

    const isCurrentUserAuthenticated = this.isCurrentUserAuthenticated();
    this.eventService.publishData({eventName: AuthenticationService.tokenRefreshSuccessEvent, isAuthenticated: isCurrentUserAuthenticated});
    console.log(AuthenticationService.tokenRefreshSuccessEvent + ': fired');
    this.router.navigateByUrl ('/tabs',{replaceUrl: true});
    return isCurrentUserAuthenticated;
  }

  private addXMLRequestCallback(callback) {
    let oldSend; let i;
    const xhr: any = XMLHttpRequest;
    if (xhr.callbacks) {
      // we've already overridden send() so just add the callback
      xhr.callbacks.push(callback);
    } else {
      // create a callback queue
      xhr.callbacks = [callback];
      // store the native send()
      oldSend = XMLHttpRequest.prototype.send;
      // override the native send()
      XMLHttpRequest.prototype.send = function () {
        // process the callback queue
        // the xhr instance is passed into each callback but seems pretty useless
        // you can't tell what its destination is or call abort() without an error
        // so only really good for logging that a request has happened
        // I could be wrong, I hope so...
        // EDIT: I suppose you could override the onreadystatechange handler though
        for (i = 0; i < xhr.callbacks.length; i++) {
          xhr.callbacks[i](this);
        }
        // call the native send()
        oldSend.apply(this, arguments);
      };
    }
  }

  public getRefreshToken(): any {
    return this.storage.get('refreshToken');
  }
  public isCurrentUserAuthenticated(): boolean {
    //todo: define appropriate authenticaiton requirements here
    return AuthenticationService.authenticationToken && AuthenticationService.authenticationToken.length > 0;
  }
}
