import {Injectable} from '@angular/core';
import { AppSettings } from 'src/app/app-settings';

@Injectable()
export class AppSettingsService {
    public getWebApiRootUrl(): string {
        return AppSettings.webApiRootUrl;
    }

    public getImagesRootUrl(): string {
        return AppSettings.imagesRootUrl;
    }

    public isAppTravelRecon(): boolean {
        return AppSettings.isAppTravelRecon;
    }

    public getWebSiteAppUrl(): string {
        return AppSettings.webSiteAppUrl;
    }

    public getWebSiteUrl(): string {
        return AppSettings.webSiteUrl;
    }

    public getSupportEmailAddress(): string {
        return AppSettings.supportEmailAddress;
    }

    public getWebSiteHelpUrl(): string {
        return AppSettings.webSiteHelpUrl;
    }

    public getAriesToken(): string {
        return AppSettings.aerisToken;
    }

    private getTimeStamp(): string {
        let date = new Date();
        return date.getFullYear().toString()
            + (date.getMonth() + 1).toString()
            + date.getDate().toString()
            + date.getHours().toString()
            + date.getMinutes().toString()
            + date.getSeconds().toString();
    }

    public getStaleContactPeriod(): number {
        return AppSettings.staleContactPeriod;
    }

    public getAppVersion(): string {
        return AppSettings.appVersion;
    }

    public getAvatarGetUrl(userDrupalId: number, reload?: boolean): string {
        var url = AppSettings.avatarGetUrl + '?id=' + userDrupalId.toString();

        if (reload) {
            url += '&v=' + this.getTimeStamp();
        }

        return url;
    }

    public getDefaultZoom(): number {
        return AppSettings.defaultZoom;
    }
}