/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from './services/authentication-service.service';
import { EventService } from './services/event.service';
import { ApplicationEvents } from './infrastructure/events/application-events';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ViewAlertPage } from './models/view-alert/view-alert.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private eventService: EventService,
    private router: Router, private toast: ToastController,
    private alertDialog: AlertController,
    private modalController: ModalController,
    private storage: Storage, private authenticationService: AuthenticationService) { }

  dev = false;

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    await this.storage.create();
    // const token = await this.storage.get('accessToken');
    // if(token){
    //   console.log(token);
    //   AuthenticationService.authenticationToken = token;
    //   ApiClientService.authenticationToken = token;
    // }
    // else{
    //   console.log('no token found');
    // }
    //Notification settings
    if (Capacitor.getPlatform() !== 'web') {
      this.initNotificationSetup();
    }
    this.eventService.getObservable().subscribe((data => {
      console.log(data.eventName);
      // eslint-disable-next-line eqeqeq
      if (data.eventName == ApplicationEvents.onUnauthorizedEvent) {
        this.authenticationService.logout();
      }
      else if (data.eventName == AuthenticationService.authenticationSuccessEvent) {
        console.log('authentication success event fired');
        if (Capacitor.getPlatform() !== 'web') {
          this.registerRequestForFCM();
        }
      }
      else if (data.eventName == AuthenticationService.tokenRefreshSuccessEvent) {
        console.log('token successfully refreshed success event fired');
      }
    }));

  }

  registerFcmTokenOnServer(value: string) {
    this.authenticationService.registerFcmToken(value).subscribe((data) => {
      console.log(data);
    }, error => {
      console.log('Couldnot register fcm token', error);
    });
  }

  initNotificationSetup() {
    console.log('Initializing Firebase Cloud messaging service');
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        this.initNotificationAddListener();
        this.registerRequestForFCM();

      } else {
        // Show some error
      }
    });

  }

  /**
   * Adds listeners for push notification events, specifically registration and errors
   * Listeners are:
   * - registration: Called when the device is registered for push notifications
   * - registrationError: Called when there is an error registering the device
   * - pushNotificationReceived: Called when a push notification is received
   * - pushNotificationActionPerformed: Called when the user interacts with a push notification
   */
  initNotificationAddListener() {
    PushNotifications.addListener('registration', async (fcmToken: Token) => {
      console.log('Push registration success token found:', fcmToken.value);
      this.processFCMToken(fcmToken);
    });
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.alertDialog.create({
          header: 'You have received a notification',
          message: '' + notification?.title,
          buttons: [{
            text: 'View',
            handler: () => {
              this.performNotificationAction(notification);
            }
          }, {
            text: 'Later',
            role: 'cancel'
          }],
          backdropDismiss: false
        }).then((alert) => {
          alert.present();
        })
      },
    );
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.performNotificationAction(notification.notification);
      },
    );
  }
  async performNotificationAction(notification: PushNotificationSchema) {
    console.log('FCMNotification action performed: ' + JSON.stringify(notification));
    const modal = await this.modalController.create({
      component: ViewAlertPage,
      componentProps: {
        alertId: notification.data.ReconId
      }
    });
    return await modal.present();
  }
  registerRequestForFCM() {
    PushNotifications.register();
  }
  async processFCMToken(fcmToken) {
    const savedfcmToken = await this.storage.get('fcmToken');
    console.log("Fcm token processing", fcmToken);
    //no saved token
    if (!savedfcmToken) {
      await this.storage.set('fcmToken', fcmToken.value);
      this.registerFcmTokenOnServer(fcmToken.value);
      this.toast.create({
        message: 'Successfully registered with Cloud Messaging and notification Service',
        duration: 3000
      }).then((toast) => toast.present());
    }
    else {
      if (savedfcmToken != fcmToken.value) {
        //new token
        //save locally
        await this.storage.set('fcmToken', fcmToken.value);
        //save remote
        this.registerFcmTokenOnServer(fcmToken.value);
        this.toast.create({
          message: 'Successfully registered with Cloud Messaging and notification Service',
          duration: 3000
        }).then((toast) => toast.present());
      }
      else {
        //same token do nothing
        //just for testing
        await this.storage.set('fcmToken', fcmToken.value);
        //save remote
        this.registerFcmTokenOnServer(fcmToken.value);
      }
    }
  }
}
