import {Injectable} from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class DeviceService {
    platform: any;
    deviceTypes: any = {
        android: 1,
      ios: 2
    };
    deviceToken: any;

    constructor(platform: Platform) {
        this.platform = platform;
    }

    public getDevice() {
        let device = {
            deviceId: null,
            deviceType: null
        };

        if (this.platform.is('android')) {
            device = {
                deviceId: this.deviceToken,
                deviceType: this.deviceTypes.android
            };
        }

        if (this.platform.is('ios')) {
            device = {
                deviceId: this.deviceToken,
                deviceType: this.deviceTypes.ios
            };
        }

        return device;
    }

    public setRegistrationToken(token) {
        this.deviceToken = token;
    }
}
