export class ApplicationEvents {
    // event fired when the app is loaded
    public static appLoadedEvent = 'global:loaded';

    // event fired when application goes to background mode
    public static appPausedEvent = 'global:pause';

    // event fired when application becomes active from background mode
    public static appResumedEvent = 'global:resume';

    public static onErrorEvent = "global:error";

    public static onUnauthorizedEvent = "global:unauthorized";

    public static openMapEvent = "pages:open-map-event";

    public static onInsufficientDataAccessEvent = "global:insufficient-data-access-event";

    public static onServerErrorEvent = "global:server-error-event";

    // process start
    public static appProcessStartEvent = 'process:start';

    // process end
    public static appProcessEndEvent = 'process:end';

    // process end
    public static onMapLoaded = 'map:loaded';
}