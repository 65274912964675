/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiClientService } from './api-client.service';
import { AppSettingsService } from './app-settings-service.service';
import { Geolocation } from '@capacitor/geolocation';
declare let cordova: any;

@Injectable()
export class MapService {

    //typings
    private w: any = window;
    private n: any = navigator;
    private geoCoderSearch = 0;
    public static lockedDestinationZoom = 8;
    public static alertPriorities: any = {
        low: 1,
        medium: 2,
        high: 3
    };

    private static layerControl: any = {};

    constructor(
        private apiClient: ApiClientService,
        private appSettings: AppSettingsService,
        private http: HttpClient,
        private platform: Platform
    ) {
    }

    public static getLayerControl(page: string) {
        const control = MapService.layerControl[page];

        if (control == null) {
            return null;
        }

        return control.getActiveBaseLayer();
    }

    public shouldShowDisablePushModal() {
        return this.apiClient.post('api/map/ShouldShowDisablePushModal', null);
    }

    public fixSatelliteLayer(map, page) {
        const activeLayer = MapService.getLayerControl(page);

        if (activeLayer != null && activeLayer.name === 'Satellite' && map.getZoom() > 17) {
            map.setZoom(17); //max zoom for Satellite
            return true;
        }

        return false;
    }

    private getLayers() {
        return this.apiClient.post('api/map/GetLayers', null);
    }

    public getMapViewModel(location) {
        return this.apiClient.post('api/map/getmapviewmodel', location);
    }

    public getMapPins(filtersParams: any) {
        //console.log(filtersParams);
        return this.apiClient.postBackground('api/map/getmappins', JSON.stringify(filtersParams));
    }

    public getLockedDestinations(location: Location, currentMapRadiusInMeters: number) {
        const params = JSON.stringify({
            location,
            radiusInMeters: currentMapRadiusInMeters
        });

        return this.apiClient.postBackground('api/mobile/map/GetLockedDestinations', params);
    }

    public getContactPins() {
        return this.apiClient.postBackground('api/map/GetContactPins', null);
    }

    public getAlertMapPins() {
        return this.apiClient.get('api/map/mobile/geojson/10000');
    }

    public getDestinationPins() {
        return this.apiClient.postBackground('api/map/GetDestinationPins', null);
    }

    public saveCurrentLocation(latitude, longitude, addressFormatted) {
        return this.apiClient.postBackground('api/map/SaveCurrentLocation', JSON.stringify({ latitude, longitude, addressFormatted }));
    }

    public saveMapCenterPoint(latitude, longitude, addressFormatted) {
        return this.apiClient.postBackground('api/map/SaveMapCenterPoint', JSON.stringify({ latitude, longitude, addressFormatted }));
    }

    public getAddressBasedOn(coords: Array<number>): Observable<any> {
        return Observable.create((obs) => {
            this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[0]},${coords[1]}.json?access_token=${this.w.mapboxgl.accessToken}`)
                //    .pipe(map((response: any) => response.json()))
                .subscribe((result: any) => {
                    if (result.features[0]) {
                        const r = {
                            latitude: coords[1],
                            longitude: coords[0],
                            address: result.features[0].place_name,
                            city: '',
                            country: ''
                        };

                        for (const key in result.features) {
                            if (result.features[key].id.indexOf('region') > -1) {
                                r.city = result.features[key].place_name.split(',')[0].trim();
                                r.country = result.features[key].place_name.split(',')[1].trim();
                                break;
                            }
                        }

                        obs.next(r);
                        obs.complete();
                    } else {
                        Observable.throw('Reverse query error');
                    }
                });
        });
    }

    public getCoordsBasedOn(query: string): Observable<any> {
        return Observable.create((obs) => {
            this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${this.w.mapboxgl.accessToken}`)
                .subscribe((result: any) => {
                    if (result.features[0]) {
                        const r = {
                            latitude: result.features[0].center[1],
                            longitude: result.features[0].center[0],
                            address: result.features[0].place_name,
                            city: '',
                            country: ''
                        };

                        for (const key in result.features) {
                            if (result.features[key].id.indexOf('region') > -1) {
                                r.city = result.features[key].place_name.split(',')[0].trim();
                                r.country = result.features[key].place_name.split(',')[1].trim();
                                break;
                            }
                        }

                        obs.next(r);
                        obs.complete();
                    } else {
                        Observable.throw('Reverse query error');
                    }
                });
        });
    }

    public getPlaces(query: string): Observable<any> {
        return this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${this.w.mapboxgl.accessToken}`);
    }

    private loadImages(map: any): void {
        // map.addImage('gradient', { width: 10, height: 10, });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/you-are-here.png', (error, image) => { map.addImage('you-are-here', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/map_pin_red.png', (error, image) => { map.addImage('map_pin_red', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/map_pin_green.png', (error, image) => { map.addImage('map_pin_green', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/map_pin_yellow.png', (error, image) => { map.addImage('map_pin_yellow', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/map_pin_orange.png', (error, image) => { map.addImage('map_pin_orange', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/contact-pin.png', (error, image) => { map.addImage('contact-pin', image); });
        map.loadImage(this.appSettings.getWebApiRootUrl() + 'api/images/get?imagePath=GoogleMapImages/MapsDefineLocationIcon.png', (error, image) => { map.addImage('MapsDefineLocationIcon', image); });

        for (let categoryId = 1; categoryId < 17; categoryId++) {
            for (let priorityId = 1; priorityId < 5; priorityId++) {
                if (this.platform.is('ios') && false) {
                    this.loadLocalFileResourceAsImageToMap(`assets/MapIcons/Map/icn_alert_${categoryId}_${priorityId}.png`, `icn_alert_${categoryId}_${priorityId}`, map);
                }
                else {
                    map.loadImage(`assets/MapIcons/Map/icn_alert_${categoryId}_${priorityId}.png`, (error, image) => { map.addImage(`icn_alert_${categoryId}_${priorityId}`, image); });
                }
            }
        }
    }

    public createMap(containerId: string, defaultZoom: number = 4, handlers: any = {}, _interactive = true): any {
    console.log('containerId', containerId);
      const map = new this.w.mapboxgl.Map({
            container: containerId,
            style: 'mapbox://styles/mapbox/streets-v9',
            minZoom: 3,
            zoom: defaultZoom,
            attributionControl: false,
            maxBounds: [[-180, -85], [180, 85]],
            interactive: _interactive
        });

        if (_interactive) {
            const geocoder = new this.w.MapboxGeocoder({
                accessToken: this.w.mapboxgl.accessToken,
                mapboxgl: this.w.mapboxgl
            });

            const nav = new this.w.mapboxgl.NavigationControl();
            map.addControl(nav, 'top-left');
            map.addControl(geocoder);
            //    if(this.geoCoderSearch == 0){
            //     document.getElementById('geocoder').appendChild(geocoder.onAdd(map));
            //     this.geoCoderSearch = 1;
            //    }

        }

        map.on('load', () => {
            this.loadImages(map);
        });

        map.on('click', (e) => {
            const features = map.queryRenderedFeatures(e.point);
            if (!features.length) {
                return;
            }

            if (features[0].properties.clickCallbackParams) {
                const paramsObj = JSON.parse(features[0].properties.clickCallbackParams);

                const action = paramsObj.action;
                const params = paramsObj.params;

                if (handlers[action]) {
                    handlers[action](params);
                }
            }
        });

        return map;
    }

    public locate(map: any): Observable<any> {
        return Observable.create(async (obs) => {
            const posOptions = { timeout: 1000, enableHighAccuracy: true };
            const crd = (await Geolocation.getCurrentPosition()).coords;
            console.log("init locate:Geolocation.getCurrentPosition", crd);
            map.setCenter([crd.longitude, crd.latitude]);

            obs.next(crd);
            obs.complete();

        });
    }



    public locateWithLocation(map: any, crd: any): Observable<any> {
        return Observable.create(async (obs) => {
            map.setCenter([crd.longitude, crd.latitude]);
            obs.next(crd);
            obs.complete();
        });
    }





    public getCurrentLocation(): Observable<any> {
        return Observable.create(async (obs) => {
            const posOptions = { timeout: 10000, enableHighAccuracy: false };
            const crd = (await Geolocation.getCurrentPosition()).coords;
            obs.next(crd);
            obs.complete();

        });
    }

    public getRadius(map: any): number {
        const bounds = map.getBounds();

        const leftTopLatLng = bounds.getNorthEast();
        const bottomRightLatLng = bounds.getSouthWest();

        return this.getDistance(leftTopLatLng.lat, leftTopLatLng.lng, bottomRightLatLng.lat, bottomRightLatLng.lng) / 2;
    }

    private getDistance(lat1, lon1, lat2, lon2): number {
        const theta = lon1 - lon2;
        let dist = Math.sin(deg2rad(lat1)) * Math.sin(deg2rad(lat2)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.cos(deg2rad(theta));

        function deg2rad(deg: number): number {
            return (deg * Math.PI / 180.0);
        }

        function rad2deg(rad: number): number {
            return (rad * 180 / Math.PI);
        }

        dist = Math.acos(dist);
        dist = rad2deg(dist);
        dist = dist * 60 * 1.1515 * 1.609344 * 1000;

        return (dist);
    }


    private loadLocalFileResourceAsImageToMap(path, name, map) {
        this.fetchLocalFileViaCordovaAsURL(path,
            function (url) {
                map.loadImage(url, (error, image) => {
                    if (error) {
                        console.error(path);
                        console.error(error);
                        throw error;
                    }
                    map.addImage(name, image);
                });
            },
            function () {
                console.error('Cannot load path:' + path + ' name' + name);
            });
    }

    private fetchLocalFileViaCordova(filename, successCallback, errorCallback) {
        //const path = cordova.file.applicationDirectory + 'www/' + filename;
        const path = 'www/' + filename;
        const newname = this.w.Ionic.WebView.convertFileSrc(path);
        successCallback(newname);

        //   this.w.resolveLocalFileSystemURL(path, function(entry) {
        //       entry.file(successCallback, errorCallback);
        //   }, errorCallback);
    };

    private fetchLocalFileViaCordovaAsArrayBuffer(filename, successCallback, errorCallback) {
        this.fetchLocalFileViaCordova(filename, function (file) {
            //   var reader = new FileReader();
            //   reader.onload = function(e) {
            //       successCallback(reader.result);
            //   };

            //   reader.readAsArrayBuffer(file);

            successCallback(file);
        }, errorCallback);
    };

    private fetchLocalFileViaCordovaAsURL(filename, successCallback, errorCallback) {
        // Convert fake Cordova file to a real Blob object, which we can create a URL to.
        this.fetchLocalFileViaCordovaAsArrayBuffer(filename, function (arrayBuffer) {
            //   var blob = new Blob([arrayBuffer]);
            //   var url = URL.createObjectURL(blob);
            //   successCallback(url);
            //   successCallback(arrayBuffer.localURL)
            successCallback(arrayBuffer);
        }, errorCallback);
    };

}
